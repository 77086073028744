import {FC} from 'react';

interface StarRatingProps {
  rating: number;
  width?: string;
  height?: string;
}

const stars = [{rating: 1}, {rating: 2}, {rating: 3}, {rating: 4}, {rating: 5}];

export const StarRating: FC<StarRatingProps> = ({
  rating,
  width = '20px',
  height = '20px',
}) => (
  <span className="flex items-center">
    {stars.map((star) => {
      const starRating = star.rating;
      const filledStars = Math.round(rating);
      const isHalfStar = rating % 1 !== 0 && starRating === filledStars;

      return (
        <span key={star.rating}>
          <svg
            width={width}
            height={height}
            aria-hidden="true"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            {star.rating === 1 && (
              <defs>
                <linearGradient id="halfGrad">
                  <stop offset={'50%'} stopColor="#fbbf24" />
                  <stop offset={'50%'} stopColor="#bbb" />
                </linearGradient>
                <linearGradient id="fullGrad">
                  <stop offset={'100%'} stopColor="#fbbf24" />
                </linearGradient>
                <linearGradient id="emptyGrad">
                  <stop offset={'100%'} stopColor="#bbb" />
                </linearGradient>
              </defs>
            )}
            <path
              fill={`url(#${
                isHalfStar
                  ? 'halfGrad'
                  : filledStars >= starRating
                  ? 'fullGrad'
                  : 'emptyGrad'
              })`}
              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
            ></path>
          </svg>
        </span>
      );
    })}
  </span>
);
